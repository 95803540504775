// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-post-contentful-js": () => import("./../src/templates/article-post-contentful.js" /* webpackChunkName: "component---src-templates-article-post-contentful-js" */),
  "component---src-templates-disorder-contentful-js": () => import("./../src/templates/disorder-contentful.js" /* webpackChunkName: "component---src-templates-disorder-contentful-js" */),
  "component---src-templates-pricing-contentful-js": () => import("./../src/templates/pricing-contentful.js" /* webpackChunkName: "component---src-templates-pricing-contentful-js" */),
  "component---src-templates-page-contentful-js": () => import("./../src/templates/page-contentful.js" /* webpackChunkName: "component---src-templates-page-contentful-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artykuly-js": () => import("./../src/pages/artykuly.js" /* webpackChunkName: "component---src-pages-artykuly-js" */),
  "component---src-pages-cennik-js": () => import("./../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("./../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-terapia-js": () => import("./../src/pages/terapia.js" /* webpackChunkName: "component---src-pages-terapia-js" */),
  "component---src-pages-w-czym-pomagam-js": () => import("./../src/pages/w-czym-pomagam.js" /* webpackChunkName: "component---src-pages-w-czym-pomagam-js" */)
}

